import nat from "./nat.png";
import coursera from "./coursera.png";
import deloitte from "./deloitte.png";
import mckinsey from "./mckinsey.png";
import prodigy from "./prodigy.png";
import teachaway from "./teachaway.png";
import clc from "./clc.png";

import "./App.css";

function App() {
  return (
    <div className="container">
      <div className="links mobile">
          <a href="mailto:nat@natcooper.com">Email</a>
          <a href="https://www.linkedin.com/in/natacoops/" target="_blank">LinkedIn</a>
        </div>
      <img src={nat} />
      <div className="content">
        <div className="links">
          <a href="mailto:nat@natcooper.com">Email</a>
          <a href="https://www.linkedin.com/in/natacoops/" rel="noreferrer" target="_blank">LinkedIn</a>
        </div>
        <div>
          <p>Hi! I’m Nat, a product designer based in Toronto.</p>
          <p>
            I have over a decade of experience crafting human-centered digital
            products and experiences, with a unique focus on education
            technology.
          </p>
          <p>Companies I’ve worked for include:</p>
        </div>
        <div className='companies'>
          <img src={coursera} />
          <img src={deloitte} />
          <img src={mckinsey} />
          <img src={prodigy} />
          <img src={teachaway} />
          <img src={clc} />
        </div>
      </div>
    </div>
  );
}

export default App;
